<template>
  <div class="news__container">
    <div class="operate d-flex justify-content-center align-items-center">
      <div class="operate__inner">
        <div class="bubble">
          <div class="bubble-overlay">
            <div class="searchbar-wrap">
              <h1 class="searchbar__title">
                我們的訊息
              </h1>

              <div class="select__wrap">
                <div class="select__bar" @click="isOpen = !isOpen">{{ showValue }}</div>
                <div class="select__options" v-if="isOpen">
                  <button v-for="(item, index) in newsClass" :key="index" @click="sctClass($event), (isOpen = !isOpen)" :value="item">{{ item }}</button>
                </div>
              </div>

            </div>
          </div>
          <img src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/bg-filter-bar.svg" class="img-fluid d-none d-md-block" alt="" />
          <img src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/bg-filter-bar-sm.svg" class="bg-filterbar-sm img-fluid d-block d-md-none" alt="" />
        </div>
      </div>
    </div>

    <!-- BANNER -->
    <template v-for="item in pagesBanner" :key="item.ID">
      <banner-single v-if="item.Pages == 'news'">
        <template #image>
          <div
            class="bannerbox__image d-none d-sm-block"
            :style="{ backgroundImage: 'url(' + item.Img + ')' }"
          ></div>
          <div
            class="bannerbox__image d-block d-sm-none"
            :style="{ backgroundImage: 'url(' + item.MImg + ')' }"
          ></div>
        </template>
        <!-- <template #operate>
          <div class="bubble">
            <div class="bubble-overlay">
              <div class="searchbar-wrap">
                <h2 class="searchbar__title">
                  我們的訊息
                </h2>

                <div class="select__wrap">
                  <div class="select__bar" @click="isOpen = !isOpen">{{ showValue }}</div>
                  <div class="select__options" v-if="isOpen">
                    <button v-for="(item, index) in newsClass" :key="index" @click="sctClass($event), (isOpen = !isOpen)" :value="item">{{ item }}</button>
                  </div>
                </div>

                <select
                  class="searchbar__select form-select"
                  @change="sctClass($event)"
                >
                  <option disabled value="">請選擇分類</option>
                  <option
                    :value="item"
                    v-for="item in newsClass"
                    :key="item.ID"
                  >
                    {{ item }}
                  </option>
                </select>
                <i class="icon-arrow-down-red"></i>

              </div>
            </div>
            <img src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/bg-filter-bar.svg" class="img-fluid d-none d-md-block" alt="" />
            <img src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/bg-filter-bar-sm.svg" class="img-fluid w-100 d-block d-md-none" alt="" />
          </div>
        </template> -->
        <template #mark>
          <img
            src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mark-banner-primary.svg"
            class="img-fluid banner__mark"
          />
        </template>
      </banner-single>
    </template>

    <!-- MAIN -->
    <article>
      <!-- SECTION_1 -->
      <section class="sec">
        <div class="sec__content container">
          <div class="news__block row">
            <ul class="news__list">
              <template v-for="item in newsList" :key="item.ID">
                <li v-if="item.PinningList == 1">
                  <router-link
                    :to="'/' + this.$route.params.lang + `/news/${item.SeoUrl}`"
                    @click="clickRouterTop"
                    class="hover-effect"
                  >
                    <card-horizontal>
                      <template #pin>
                        <img
                          src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/icon-pushpin.svg"
                          class="pin"
                          alt=""
                        />
                      </template>
                      <template #image>
                        <div class="imgbox__inner imgbox--ratio-7x5 adjust">
                          <div
                            class="imgbox__image d-none d-sm-block"
                            :style="{
                              backgroundImage: 'url(' + item.Img + ')',
                            }"
                          ></div>
                          <div
                            class="imgbox__image d-block d-sm-none"
                            :style="{
                              backgroundImage: 'url(' + item.MImg + ')',
                            }"
                          ></div>
                        </div>
                      </template>
                      <template #text>
                        <div class="card-info">
                          <span class="card-type"><span class="deco-border"></span>{{ item.NewsClass }}</span
                          ><time class="card-date font-enUS">{{ $filters.dateFormat(item.Date) }}</time>
                        </div>
                        <h2 class="card-title single-ellipsis">{{ item.Title }}</h2>
                        <p class="card-text multiline-ellipsis">{{ item.SubTitle }}</p>
                      </template>
                    </card-horizontal>
                  </router-link>
                </li>
                <li v-else>
                  <router-link
                    :to="'/' + this.$route.params.lang + `/news/${item.SeoUrl}`"
                    @click="clickRouterTop"
                    class="hover-effect"
                  >
                    <card-horizontal>
                      <template #pin>
                        <img
                          src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/icon-pushpin.svg"
                          class="pin d-none"
                          alt=""
                        />
                      </template>
                      <template #image>
                        <div class="imgbox__inner imgbox--ratio-7x5">
                          <div
                            class="imgbox__image d-none d-sm-block"
                            :style="{
                              backgroundImage: 'url(' + item.Img + ')',
                            }"
                          ></div>
                          <div
                            class="imgbox__image d-block d-sm-none"
                            :style="{
                              backgroundImage: 'url(' + item.MImg + ')',
                            }"
                          ></div>
                        </div>
                      </template>
                      <template #text>
                        <div class="card-info">
                          <span class="card-type"><span class="deco-border"></span>{{ item.NewsClass }}</span
                          ><time class="card-date font-enUS">{{ $filters.dateFormat(item.Date) }}</time>
                        </div>
                        <h2 class="card-title single-ellipsis">{{ item.Title }}</h2>
                        <p class="card-text multiline-ellipsis">{{ item.SubTitle }}</p>
                      </template>
                    </card-horizontal>
                  </router-link>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import axios from "axios";

import BannerSingle from "@/components/BannerSingle.vue";
import CardHorizontal from "@/components/CardHorizontal.vue";

export default {
  components: {
    BannerSingle,
    CardHorizontal,
  },
  data() {
    return {
      seoData: null,
      sTitle: null,
      sDescription: null,
      sKeywords: null,
      sSiteName: null,
      sImage: null,
      sType: null,
      currentURL: null,
      isOpen: false,
      showValue: "請選擇分類",
      pagesBanner: [],
      newsClass: [],
      newsList: [],
      newsListAll: [],
    };
  },
  created() {
    var seoFD = new FormData();
    seoFD.append("mode", "raw_sct");
    seoFD.append("data", '{"class":"HciSEO","lang":"zh-TW"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: seoFD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      const currtUrlName = this.$route.name.toLowerCase();
      this.seoData = res.data.data.filter(item => (item.SetPage == currtUrlName));
      this.sTitle = this.seoData[0].title;
      this.sDescription= this.seoData[0].description;
      this.sKeywords = this.seoData[0].keywords;
      this.sSiteName = this.seoData[0].site_name;
      this.sImage = this.seoData[0].image;
      this.sType = this.seoData[0].type;
      this.currentURL = window.location.href;
      
      console.log("this.currentURL=====>", this.currentURL)

      const siteTitle = document.querySelector("title");
      siteTitle.innerText = this.sTitle;

      const seoDescriptionD = document.querySelector("meta[name='description']");
      seoDescriptionD.setAttribute('content', this.sDescription);

      const seoTitle = document.querySelector("meta[property='og:title']");
      seoTitle.setAttribute('content', this.sSiteName);

      const seoDescription = document.querySelector("meta[property='og:description']");
      seoDescription.setAttribute('content', this.sDescription);

      const seoSiteName = document.querySelector("meta[property='og:site_name']");
      seoSiteName.setAttribute('content', this.sSiteName);

      const seoImg = document.querySelector("meta[property='og:image']");
      seoImg.setAttribute('content', this.sImage);

      const seoType = document.querySelector("meta[property='og:type']");
      seoType.setAttribute('content', this.sType);

      const seoKeywords = document.querySelector("meta[name='keywords']");
      seoKeywords.setAttribute('content', this.sKeywords);

      const seoURL = document.querySelector("meta[property='og:url']");
      seoURL.setAttribute('content', this.currentURL);
    });

    var FD = new FormData();
    FD.append("mode", "raw_sct");
    FD.append("data", '{"class":"PagesBanner","lang":"zh-TW","sw":""}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.pagesBanner = res.data.data;
    });

    var FD2 = new FormData();
    FD2.append("mode", "raw_sct");
    FD2.append("data", '{"class":"HciNews","lang":"zh-TW","sw":""}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD2,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.newsClass = res.data.NewsClass;
      this.newsList = res.data.data
        .sort(function (a, b) {
          return a["Date"] < b["Date"] ? 1 : -1;
        })
        .sort(function (a) {
          return a["PinningList"] == "1" ? 0 : -1;
        })
        .reverse();
      this.newsListAll = this.newsList;
    });
  },
  methods: {
    clickRouterTop(){
      //chrome
      document.body.scrollTop = 0
      //firefox
      document.documentElement.scrollTop = 0
      //safari
      window.pageYOffset = 0
    },
    sctClass(e) {
      console.log(e.target.value);
      this.showValue = e.target.value;
      if (e.target.value == "全部") {
        this.newsList = this.newsListAll;
      } else {
        this.newsList = this.newsListAll
          .filter((item) => item.NewsClass == e.target.value)
          .sort(function (a, b) {
            return a["Date"] < b["Date"] ? 1 : -1;
          })
          .sort(function (a) {
            return a["PinningList"] == "1" ? 0 : -1;
          })
          .reverse();
      }
    },
  },
};
</script>
<style lang="scss">
.news__container {
  position: relative;
  .operate {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    padding-top: 31%;
    z-index: 99;
    .operate__inner {
      position: absolute;
      top: 6rem;
      @media (max-width:576px) {
        top: 10rem;
      }
    }
  }
}

.bg-filterbar-sm {
  width: 100%;
  @media (max-width:576px) {
    width: 390px;
  }
  @media (max-width:375px) {
    width: 350px;
  }
  @media (max-width:320px) {
    width: 300px;
    transform: scale(1,1.2);
  }
}

</style>