<template>
  <div class="card custom-border bg-white mb-4">
    <slot name="pin"></slot>

    <div class="row">
      <div class="col-lg-5">
        <div class="imgbox">
          <slot name="image"></slot>
        </div>
      </div>
      <div class="col-lg-7 d-flex align-items-start">
        <div class="card-body d-flex flex-column">
          <slot name="text"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
